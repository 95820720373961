import React from "react";
import './tools.css';
import { FaHtml5, FaJava, FaCss3Alt, FaPython, FaJs, FaWordpress, FaSquarespace } from "react-icons/fa";
import TypeWriter from 'typewriter-effect';
import spainFlag from '../../images/spain.png';
import argFlag from '../../images/argentina.png';
import usaFlag from '../../images/united-states.png';   
import Basic from '../../images/img12.png';
import Intermediate from '../../images/plan-intermedio.png';     
import Full from '../../images/plan-full.png';

const Tools = () => {

    return (
         <>        
    <div className="contentText">
            <h1>Joaquin Quinteros</h1>
            <TypeWriter onInit={(typewriter) => 
            {typewriter.typeString("I am a systems engineer passionate about technology, who loves challenges and problem solving. What motivated me to carry out different types of projects throughout my career such as software development, web pages, data analysis and business intelligence among others. Each project is unique, which implies that the best technology must be used for each one, for this reason I have trained in a wide variety of technologies and tools to be able to carry out each project in the most optimal way.").start();}}/>
            <br/>
            <h1>Fernando Penfold</h1>
            <TypeWriter onInit={(typewriter) => 
            {typewriter.typeString("I am a passionate graduate in Social Communication with solid experience in website design and development. My focus is on creating attractive and functional WordPress (using tools like Divi and Elementor) and Squarespace websites.").start();}}/>    
            </div>
            <div className="titleAndButton" >
            <TypeWriter onInit={(typewriter) => {typewriter.typeString("My Tools").start();}}/>
            </div>
            <div class="row">
                <FaPython className='iconTool' />
                <FaJava className='iconTool' />
                <FaHtml5 className='iconTool'/>
                <FaCss3Alt className='iconTool' />
                <FaJs className='iconTool' />
                <FaWordpress className='iconTool' />
                <FaSquarespace className='iconTool' />
            </div>
            <div className="titleAndButton" >
            <TypeWriter onInit={(typewriter) => {typewriter.typeString("Countries where we developed").start();}}/>
            </div>
            <div class="row">
                <img src={argFlag} className='iconToolFlag' alt="spain"/>
                <img src={usaFlag} className='iconToolFlag'alt="argentina"/>
                <img src={spainFlag} className='iconToolFlag' alt="usa"/>
            </div>
            <div className="titleAndButton" >
            <TypeWriter onInit={(typewriter) => {typewriter.typeString("Services").start();}}/>
            </div>
            <div class="row-plan">
                <div className='box'>
                    <div className="boxText">
                        <h1>Landing</h1>
                        <p>Landing page without ecommerce</p> 
                        <p>   1 section </p>
                     {/*   <p><strong>From U$D 200</strong></p> */}
                    </div>
                <div className="blur">
                    <img src={Basic} alt="basic"></img>
                </div>   
                </div>
                <div className='box'>
                    <div className="boxText">
                        <h1>Web</h1>
                        <p>Website without ecommerce</p> 
                        <p>2 or more sections </p>
                     {/*   <p><strong>From U$D 300</strong></p> */}
                    </div>
                <div className="blur">
                    <img src={Intermediate} alt="intermediate"></img>
                </div>       
                </div>
                <div className='box'>
                    <div className="boxText">
                        <h1>ecommerce</h1>
                        <p>Website with product catalog</p>
                        <p>2 or more sections</p>
                       {/* <p><strong>From U$D 400</strong></p> */}
                    </div>
                <div className="blur">
                    <img src={Full} alt="full"></img>
                </div>
                         
                </div>
            </div>
            </>


    
     )
    


};
export default Tools; 