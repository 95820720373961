import React from "react";
import TypeWriter from 'typewriter-effect'; 
import './portfolioGallery.css';
import Img1 from '../../images/img1.webp';
import Img2 from '../../images/img2.webp';
import Img3 from '../../images/img3.png';
import Img4 from '../../images/img4.webp';
import Img5 from '../../images/img5.png';
import Img6 from '../../images/img6.png';
import Img7 from '../../images/img7.png';
import Img8 from '../../images/img8.png';
import Img9 from '../../images/img9.webp';
import Img10 from '../../images/img10.png';
import Img11 from '../../images/img11.png';
import Img12 from '../../images/img12.png';
import Img13 from '../../images/img13.png';
import Img14 from '../../images/img14.png';
import Img15 from '../../images/img15.png';
import Img16 from '../../images/img16.png';
import Img17 from '../../images/img17.png';
import Img18 from '../../images/img18.png';
import Img19 from '../../images/img19.png';
import Img20 from '../../images/img20.png';
import Img21 from '../../images/img21.png';
import Img22 from '../../images/img22.png';
import Img23 from '../../images/img23.png';
import Img24 from '../../images/img24.png';
import Img25 from '../../images/img25.png';
import Img26 from '../../images/img26.png';
import Img27 from '../../images/img27.png';
import Img28 from '../../images/img28.png';
import trailer1 from '../../images/trailer1.webm';
import trailer2 from '../../images/trailer2.webm';
import trailer3 from '../../images/trailer3.webm';
import trailer4 from '../../images/trailer4.webm';
import trailer5 from '../../images/trailer5.webm';
import trailer6 from '../../images/trailer6.webm';
import trailer7 from '../../images/trailer7.webm';
import trailer8 from '../../images/trailer8.webm';
import trailer9 from '../../images/trailer9.webm';
import trailer10 from '../../images/trailer10.webm';
import trailer11 from '../../images/trailer11.webm';
import trailer12 from '../../images/trailer12.webm';
import trailer13 from '../../images/trailer13.webm';
import trailer14 from '../../images/trailer14.webm';
import trailer15 from '../../images/trailer15.webm';
import trailer16 from '../../images/trailer16.webm';
import trailer17 from '../../images/trailer17.webm';
import trailer18 from '../../images/trailer18.webm';
import trailer19 from '../../images/trailer19.webm';
import trailer20 from '../../images/trailer20.webm';
import trailer21 from '../../images/trailer21.webm';
import trailer22 from '../../images/trailer22.webm';
import trailer23 from '../../images/trailer23.webm';
import trailer24 from '../../images/trailer24.webm';
import trailer25 from '../../images/trailer25.webm';
import trailer26 from '../../images/trailer26.webm';
import trailer27 from '../../images/trailer27.webm';
import trailer28 from '../../images/trailer28.webm';
import { createRoot } from "react-dom/client";
import AnimCursor from '../AnimCursor';
import close from '../../Components/portfolio/close.png';    

const Gallery = () => {
    let data =[{id:1,imgSrc:Img1,vidSrc:trailer1,},
        {id:2,imgSrc:Img2,vidSrc:trailer2,},
        {id:3,imgSrc:Img3,vidSrc:trailer3,},
        {id:4,imgSrc:Img4,vidSrc:trailer4,},
        {id:6,imgSrc:Img6,vidSrc:trailer6,},
        {id:7,imgSrc:Img7,vidSrc:trailer7,},
        {id:5,imgSrc:Img5,vidSrc:trailer5,},
        {id:8,imgSrc:Img8,vidSrc:trailer8,},
        {id:9,imgSrc:Img9,vidSrc:trailer9,},
        {id:10,imgSrc:Img10,vidSrc:trailer10,},
        {id:11,imgSrc:Img11,vidSrc:trailer11,},
        {id:12,imgSrc:Img12,vidSrc:trailer12,},
        {id:13,imgSrc:Img13,vidSrc:trailer13,},
        {id:14,imgSrc:Img14,vidSrc:trailer14,},
        {id:15,imgSrc:Img15,vidSrc:trailer15,},
        {id:16,imgSrc:Img16,vidSrc:trailer16,},
        {id:17,imgSrc:Img17,vidSrc:trailer17,},
        {id:18,imgSrc:Img18,vidSrc:trailer18,},
        {id:19,imgSrc:Img19,vidSrc:trailer19,},
        {id:20,imgSrc:Img20,vidSrc:trailer20,},
        {id:21,imgSrc:Img21,vidSrc:trailer21,},
        {id:22,imgSrc:Img22,vidSrc:trailer22,},
        {id:23,imgSrc:Img23,vidSrc:trailer23,},
        {id:24,imgSrc:Img24,vidSrc:trailer24,},
        {id:25,imgSrc:Img25,vidSrc:trailer25,},
        {id:26,imgSrc:Img26,vidSrc:trailer26,},
        {id:27,imgSrc:Img27,vidSrc:trailer27,},
        {id:28,imgSrc:Img28,vidSrc:trailer28,},]
    
    const container = document.getElementById('repVideo');
    const toggleVideo = (event, item, isOpen) =>{
    if (isOpen)
    {  
     createRoot(document.getElementById('repVideo')).render(    <div id={"trailer-gallery-"+item.id} className="trailer-gallery video-active">
                                     <video id={item.id} className="video-gallery" src={item.vidSrc} loop muted autoPlay controls controlsList="nodownload" />
                                     <img src={close} className="close" alt="close" onClick={(event) => toggleVideo(event, item, false)}></img>
                                     <AnimCursor/>
                                 </div>
         ,container);
     }
     else
     {
     var f = document.getElementsByClassName("trailer-gallery video-active")[0];
 
     f.remove()
     
 
     }
    };
    return (

            <>
            <div className="titleAndButton" id="portfolio" >
            <TypeWriter onInit={(typewriter) => {typewriter.typeString("Portfolio").start();}}/>
            <br/>
            <div className="gallery">
                {
                    data.map((item, index) =>{return(
                        <div>
                        <div className="pics" key={index}>
                            <img src={item.imgSrc} style={{width: '100%'}} alt={item} onClick={(event) => toggleVideo(event, item, true)}/>
                        </div>
                        </div>



                    )})
                }
            </div>
            </div>
            </>
    
     )


};
export default Gallery; 