import { FaInstagram,FaLinkedinIn, FaMailBulk } from "react-icons/fa";
import './footer.css';
import TypeWriter from 'typewriter-effect';     
const Footer = () => {

    return(
        <div className="titleAndButton" id="contact">
        <TypeWriter onInit={(typewriter) => {typewriter.typeString("Contact").start();}}/>
        
            <div className="footer-row">
                <a href='https://www.instagram.com/agencia.cosmos/'><FaInstagram className='iconTool'/></a>
                <a href='https://www.linkedin.com/company/agenciacosmos'><FaLinkedinIn className='iconTool'/></a>
                <a href='mailto:desarrollowebcosmos@gmail.com'><FaMailBulk className='iconTool' /></a>
            </div>
            </div>
  
  
);


};
export default Footer; 